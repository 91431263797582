import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import breakPoints from '../../styles/helpers/breakpoints'

const width = 2650

const baseWaveStyles = css`
  width: ${width}px;
  margin-bottom: -2px;
`

const styles = {
  container: (fill, bg) => css`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    overflow: hidden;
    fill: ${fill};
    background: ${bg};
    z-index: -1;
    display: flex;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    border: 0;
    padding-top: 1px;
    margin-top: -1px;
    margin-bottom: -2px;
    ${breakPoints({
      width: [width * 0.7, width * 1, width * 2, width * 3],
    })}
  `,

  wave1: css`
    transform: rotateY(180deg);
    ${baseWaveStyles};
    ${breakPoints({
      height: [33, 47, 94, 150, 150],
    })}
  `,

  wave2: css`
    ${baseWaveStyles};
    ${breakPoints({
      height: [60, 86, 172, 250, 250],
    })}
  `,

  wave3: css`
    ${baseWaveStyles};
    ${breakPoints({
      height: [52, 74, 148, 224, 224],
    })}
  `,

  waveFooter: css`
    ${baseWaveStyles};
    ${breakPoints({
      height: [48, 68, 138, 206, 206],
    })}
  `,
}

const Wave1 = () => (
  <svg viewBox='0 0 2560 150' css={styles.wave1}>
    <path d='M0 150h2560V96.61c-46-50.27-102-58.762-168-25.475-223.469 112.705-366-15.285-432-40.76-66-25.474-115.01-5.708-149 7.001-109 40.76-150 86.613-250 86.613-163.712 0-226-93.746-392-119.22-166-25.474-290 57.95-400 88.65-110 30.703-244-81.385-428-88.518C218.333.146 104.667 30.715 0 96.61z' />
  </svg>
)
const Wave2 = () => (
  <svg viewBox='0 0 2560 250' css={styles.wave2}>
    <path d='M758.095 102.428c88 32.071 158 71.158 282.905 70.957 124.905-.2 173.095-52.917 397.095-127.082 224-74.165 392.094-41.975 481-21.047C2008 46.184 2079 90.282 2258 160.438c119.333 46.771 220 57.1 302 30.987V250H0v-58.575C58.667 121.27 156 72.83 292 46.102 496 6.013 713.896 86.32 758.095 102.428z' />
  </svg>
)
const Wave3 = () => (
  <svg viewBox='0 0 2560 224' css={styles.wave3}>
    <path d='M2560 224v-37.256c-119.268-93.004-233.935-105.512-344-37.524-165.098 101.983-305.902 46.557-346.338 16.74-92.863-68.474-182.302-158.482-383.045-85.98-200.743 72.503-348.962 93.58-517.571-9.023C800.437-31.646 697.116.237 641.058 24.547 585 48.856 521.472 128.381 403 149.22c-97 17.062-173-40.145-256-40.145-55.333 0-104.333 25.89-147 77.669V224z' />
  </svg>
)
const WaveFooter = () => (
  <svg viewBox='0 0 2561.803 200' css={styles.waveFooter}>
    <path d='M432 156.665c119.247 8.9 176-23.102 307-77.315C788.685 58.787 913 0 1031 0s242.92 104.46 377 104.46c147 0 202.27-54.942 308-75.332 125-24.106 190.446 48.008 228 75.332 40 29.103 82 76.311 149 76.311s151.22-81.05 219-108.477c92.667-37.499 175.333-27.044 248 31.365V200H0v-96.341C53.333 82.9 107 74.865 161 79.553c81 7.03 126 66.292 271 77.112z' />
  </svg>
)

const WaveType1 = () => (
  <>
    <Wave1 />
    <Wave1 />
    <Wave1 />
  </>
)
const WaveType2 = () => (
  <>
    <Wave2 />
    <Wave2 />
    <Wave2 />
  </>
)
const WaveType3 = () => (
  <>
    <Wave3 />
    <Wave3 />
    <Wave3 />
  </>
)
const WaveTypeFooter = () => (
  <>
    <WaveFooter />
    <WaveFooter />
    <WaveFooter />
  </>
)

function Wave({ bg, fill, type, className }) {
  const Tag = {
    1: WaveType1,
    2: WaveType2,
    3: WaveType3,
    footer: WaveTypeFooter,
  }[type]

  return (
    <div css={styles.container(fill, bg)}>
      <Tag className={className} bg={bg} fill={fill} />
    </div>
  )
}

Wave.propTypes = {
  bg: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
}

Wave.defaultProps = {
  type: '1',
  className: '',
}

export default Wave
